<template>
  <div class="rays-tuner-image">
    <div class="image-top">
      <div class="image-title">画质检测任务</div>
      <div class="image-top-tools">
        <a-input
          placeholder="可输入PAT名称关键词"
          v-model:value="searchName"
          @keyup.enter="searchPat"
          class="top-input"
        >
          <template #prefix>
            <SearchOutlined />
          </template>
        </a-input>
        <a-select
          ref="select"
          v-model:value="selectValue"
          style="width: 120px"
          @change="imageSelect"
        >
          <a-select-option value="">全部状态</a-select-option>
          <a-select-option value="6">已完成</a-select-option>
          <a-select-option value="2">进行中</a-select-option>
          <a-select-option value="1">队列中</a-select-option>
          <a-select-option value="4">手动停止</a-select-option>
          <a-select-option value="5">系统停止</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="image-body">
      <div class="image-body-list" v-show="paginationImage.total > 0">
        <a-table
          id="image_body_scroll"
          :columns="columns"
          :data-source="imageData"
          :scroll="{ x: '1600px', y: 'calc(100vh - 200px)' }"
          :showHeader="false"
          :pagination="paginationImage"
        >
          <template #image="{ record }">
            <div class="image-list">
              <a-image
                :width="72"
                :height="47"
                :src="record.image"
                :preview="false"
                :fallback="imgSvg"
              />
            </div>
          </template>
          <template #name="{ record }">
            <div style="font-size: 16px; color: #3c3c3c; font-weight: 500">
              {{ record.name }}
            </div>
            <a-tooltip placement="bottomLeft" :destroyTooltipOnHide="true">
              <template #title>
                <span>{{ record.pat }}</span>
              </template>
              <div
                style="color: #8c8c8c; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
              >
                {{ record.pat }}
              </div>
            </a-tooltip>
          </template>
          <template #time="{ record }">
            <div v-if="record.status === '6'">
              <span style="color: #3c3c3c">提交时间：{{ record.subTime }}</span>
              <span style="margin-left: 48px; color: #3c3c3c"
                >总用时：{{ record.allTime }}</span
              >
            </div>
            <div v-else-if="record.status === '2'">
              <a-progress
                size="small"
                :percent="50"
                :show-info="false"
                strokeColor="#604BDC"
                :strokeWidth="4"
              />
            </div>
            <div v-else-if="record.status === '0' || record.status === '1'">
              <a-progress
                size="small"
                :percent="0"
                :show-info="false"
                strokeColor="#8C8C8C"
                :strokeWidth="4"
              />
            </div>
            <div v-else>
              <a-progress
                size="small"
                :percent="50"
                :show-info="false"
                strokeColor="#8C8C8C"
                :strokeWidth="4"
              />
            </div>
          </template>
          <template #status="{ record }">
            <a-tag v-show="record.status === '6'" color="purple">已完成</a-tag>
            <a-tag v-show="record.status === '2'" color="#604BDC">进行中</a-tag>
            <a-tag v-show="record.status === '4'" color="orange"
              >手动停止</a-tag
            >
            <a-tag v-show="record.status === '3'" color="orange"
              >手动停止中</a-tag
            >
            <a-tooltip placement="top">
              <template #title>
                <span>系统错误</span>
              </template>
              <a-tag
                v-show="record.status === '5'"
                color="red"
                style="cursor: pointer"
                >系统停止 <QuestionCircleFilled
              /></a-tag>
            </a-tooltip>
            <a-tag v-show="record.status === '8'" color="red">删除中</a-tag>
            <a-tag v-show="record.status === '0' || record.status === '1'"
              ><span style="color: #aaaaaa">队列中</span></a-tag
            >
          </template>
          <template #action="{ record }">
            <!--已完成-->
            <a-tooltip
              v-if="record.status === '6'"
              placement="bottom"
              class="search_icon"
            >
              <template #title>查看</template>
              <FileTextOutlined
                @click="watchClick(record)"
                style="font-size: 16px"
              />
            </a-tooltip>
            <!--进行中-->
            <a-tooltip
              v-if="
                record.status === '2' ||
                  record.status === '1' ||
                  record.status === '0'
              "
              placement="bottom"
              class="search_icon"
            >
              <template #title>停止</template>
              <PauseOutlined
                @click="stopClick(record)"
                style="font-size: 16px"
              />
            </a-tooltip>
            <!--用户操作已停止-->
            <a-tooltip
              v-if="record.status === '4'"
              placement="bottom"
              class="search_icon"
            >
              <template #title>开始</template>
              <CaretRightOutlined
                @click="startClick(record)"
                style="font-size: 16px"
              />
            </a-tooltip>
            <a-tooltip
              v-if="record.status === '5'"
              placement="bottom"
              class="search_icon"
            >
              <template #title>系统停止</template>
              <ReloadOutlined
                @click="startClick(record)"
                style="font-size: 16px"
              />
            </a-tooltip>
            <!-- <a-tooltip
              v-if="record.status === '0'"
              placement="bottom"
              class="search_icon"
            >
              <template #title>队列中,请稍后操作</template>
              <PauseOutlined style="font-size: 16px" />
            </a-tooltip> -->
            <!--用户删除中-->
            <a-tooltip
              v-if="record.status === '8'"
              placement="bottom"
              class="delete_icon"
            >
              <template #title>删除中,请稍后操作</template>
              <LoadingOutlined style="font-size: 16px" />
            </a-tooltip>
            <!--用户操作停止中-->
            <a-tooltip
              v-else-if="record.status === '3'"
              placement="bottom"
              class="delete_icon"
            >
              <template #title>停止中，请稍后操作</template>
              <LoadingOutlined style="font-size: 16px" />
            </a-tooltip>
            <a-tooltip v-else placement="bottom" class="delete_icon">
              <template #title>删除</template>
              <DeleteOutlined
                @click="deleteClick(record)"
                style="font-size: 16px"
              />
            </a-tooltip>
          </template>
        </a-table>
      </div>
      <div class="image-body-empty" v-show="paginationImage.total == 0">
        <div class="empty-con">
          <div class="empty-icon"></div>
          <div class="empty-tip">暂无任务</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  createVNode,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import {
  SearchOutlined,
  FileTextOutlined,
  DeleteOutlined,
  QuestionCircleFilled,
  ReloadOutlined,
  PauseOutlined,
  CaretRightOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons-vue';
import PerfectScrollbar from 'perfect-scrollbar';
import { message, Modal } from 'ant-design-vue';
import store from '@/vuex/store';
import moment from 'moment';
import router from '@/routes';
import api from '@/api/task';
import { imgSvg72_47 as imgSvg } from '@/utils/img';

const columns = [
  {
    key: 'image',
    dataIndex: 'image',
    slots: { customRender: 'image' },
    width: '6%',
    fixed: 'left',
  },
  {
    key: 'name',
    dataIndex: 'name',
    slots: { customRender: 'name' },
    width: '20%',
    fixed: 'left',
  },
  {
    key: 'time',
    dataIndex: 'time',
    slots: { customRender: 'time' },
  },
  {
    key: 'status',
    dataIndex: 'status',
    slots: { customRender: 'status' },
    width: '15%',
  },
  {
    key: 'action',
    dataIndex: 'action',
    slots: { customRender: 'action' },
    width: '10%',
    fixed: 'right',
  },
];
export default defineComponent({
  name: 'ImageQuality',
  components: {
    SearchOutlined,
    FileTextOutlined,
    DeleteOutlined,
    QuestionCircleFilled,
    ReloadOutlined,
    PauseOutlined,
    CaretRightOutlined,
    LoadingOutlined,
  },
  setup() {
    const searchName = ref('');
    const selectValue = ref('');
    const statusValue = ref('');
    let imageData = ref([]);
    const currentPage = ref(1);
    const paginationImage = ref({
      size: 'small',
      showQuickJumper: true,
      position: 'bottom',
      hideOnSinglePage: true,
      pageSize: 9,
      total: 0,
      onChange: (page) => {
        // console.log(page);
        currentPage.value = page;
        imageData.value = [];
        getImageList();
      },
    });

    const searchPat = () => {
      // console.log(searchName.value);
      imageData.value = [];
      getImageList();
    };

    const imageSelect = () => {
      console.log(selectValue.value);
      imageData.value = [];
      if (selectValue.value == '1' || selectValue.value == '0') {
        statusValue.value = '1,0';
      } else if (selectValue.value == '4') {
        statusValue.value = '4,3';
      } else {
        statusValue.value = selectValue.value;
      }
      getImageList();
    };

    const format = (start, end) => {
      // console.log(start,end);
      if (
        !start ||
        !end ||
        start == '0000-00-00 00:00:00' ||
        end == '0000-00-00 00:00:00'
      ) {
        return "00'00'00";
      }
      // 对比两个时间相差多少秒
      const second = moment(start).diff(moment(end), 'seconds');
      // 把数值型的毫秒值转化为时分秒格式
      const res = moment.utc(Math.abs(second) * 1000).format(`HH'mm'ss`);
      // console.log('formateDate',start,end,second, second/60,second/60/60, res);
      return res;
    };
    const handleImageList = (imageList) => {
      // console.log('imageList:',imageList)
      for (let i = 0; i < imageList.length; i++) {
        let obj = {
          key: imageList[i].id,
          image: '图片',
          name: imageList[i].task_number,
          pat: imageList[i].pat_name,
          subTime: imageList[i].add_time,
          allTime: format(imageList[i].start_time, imageList[i].end_time),
          status: imageList[i].status,
          taskNumber: imageList[i].task_number,
        };
        imageData.value.push(obj);
      }
      // console.log('imageData:',imageData.value);
    };
    const getImageList = async () => {
      const user_name = store.state.user.userinfo;
      // console.log('id',user_name);
      const params = {
        type_id: 6,
        user_id: user_name.uid,
        pat_name: searchName.value,
        status: statusValue.value,
      };
      const url = `/index.php/Task/gets?page=${currentPage.value}&pageSize=${paginationImage.value.pageSize}`;
      const res = await api.taskGets(url, params);
      if (!res) return;
      if (res.code == 0) {
        paginationImage.value.total = res.total || 0;
        handleImageList(res.data);
      } else {
        message.error(' 获取画质检测列表失败！');
      }
    };
    getImageList();

    const watchClick = (record) => {
      console.log('watchClick', record);
      router.push({
        path: `/imageQualityDetail/${record.pat}/${record.taskNumber}`,
      });
    };
    const stopClick = async (record) => {
      const user_name = store.state.user.userinfo;
      const params = {
        user: user_name.email,
        task_id: record.taskNumber,
      };
      const res = await api.stopAsyncTask(params);
      if (!res) return;
      if (res.code == 0) {
        imageData.value = [];
        getImageList();
        message.success('画质检测任务已停止！');
      } else {
        message.error('画质检测任务停止失败：' + res.detail);
      }
    };
    const startClick = async (record) => {
      const user_name = store.state.user.userinfo;
      const params = {
        user: user_name.email,
        task_id: record.taskNumber,
      };
      const res = await api.continueAsyncTask(params);
      if (!res) return;
      if (res.code == 0) {
        imageData.value = [];
        getImageList();
        message.success('画质检测任务已继续启动！');
      } else {
        message.error('画质检测任务启动失败：' + res.detail);
      }
    };
    const deleteClick = (record) => {
      const user_name = store.state.user.userinfo;
      Modal.confirm({
        title: () => '确定要删除所选任务吗？',
        icon: createVNode(ExclamationCircleOutlined),
        okText: '删除',
        okType: 'danger',
        async onOk() {
          const params = {
            user: user_name.email,
            task_id: record.taskNumber,
          };
          const res = await api.deleteAsyncTask(params);
          if (!res) return;
          if (res.code == 0) {
            imageData.value = [];
            getImageList();
            message.success('删除成功！');
          } else {
            message.error(res.detail);
          }
        },
      });
    };

    let imageListDOM = null;
    let imageListPs = null;
    onMounted(() => {
      imageListDOM = document.querySelector(
        '#image_body_scroll .ant-table-body'
      );
      imageListPs = new PerfectScrollbar(imageListDOM);
    });
    onBeforeUnmount(() => {
      if (imageListPs) {
        imageListPs.destroy();
        imageListPs = null;
      }
      imageListDOM = null;
    });

    return {
      imageData,
      imgSvg,
      columns,
      searchName,
      selectValue,
      paginationImage,
      searchPat,
      imageSelect,
      getImageList,
      watchClick,
      stopClick,
      startClick,
      deleteClick,
    };
  },
});
</script>

<style scoped>
.rays-tuner-image {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.image-top {
  width: 100%;
  height: 72px;
  border-bottom: 1px solid #d8d8d8;
  padding: 0 40px;
}
.image-title {
  float: left;
  width: 120px;
  height: 72px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  text-align: LEFT;
  color: #222222;
  line-height: 72px;
}
.image-top-tools {
  width: 473px;
  height: 72px;
  float: right;
  padding: 20px 0;
}
.top-input {
  width: 320px;
  color: #aaaaaa;
  margin-right: 32px;
}
.image-body {
  /*width: 100%;*/
  /*height: 100%;*/
  position: relative;
  overflow: hidden !important;
}
.image-body-list {
  padding: 0 20px;
}
.ant-progress-line {
  width: 350px;
}
#image_body_scroll {
  /*height: 100%;*/
}
.image-list {
  width: 72px;
  height: 48px;
  border-radius: 4px;
  background-color: #222222;
}
.search_icon {
  width: 32px;
  height: 32px;
  margin-right: 16px;
  padding-top: 7px;
  border-radius: 4px;
  color: #8c8c8c;
}
.search_icon:hover {
  color: #ffffff;
  background-color: #604bdc;
}
.delete_icon {
  width: 32px;
  height: 32px;
  padding-top: 7px;
  border-radius: 4px;
  color: #8c8c8c;
}
.delete_icon:hover {
  color: #ffffff;
  background-color: #ff2222;
}
.empty-con {
  position: relative;
  width: 168px;
  height: 174px;
  margin: 320px auto;
}
.empty-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background: url('~@/assets/Inbox.png') no-repeat;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}
.empty-tip {
  width: 56px;
  height: 22px;
  margin: 8px auto;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #d8d8d8;
  line-height: 22px;
}
</style>
<style>
#image_body_scroll .ps__rail-x,
#image_body_scroll .ps__rail-y {
  z-index: 2;
}
</style>
